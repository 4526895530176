import h from 'hyperscript';
import htm from 'htm';

export const html = htm.bind(h);

export function nonnull<T>(
  value: T | null | undefined,
  message?: string,
): T {
  if (value == null) { // Which checks both `null` and `undefined`
    throw new Error(message ?? `Expected non-nullish value`);
  }
  return value;
}

export function readablizeBytes(n: number) {
  var s = ['', 'kB', 'MB', 'GB', 'TB', 'PB'];
  var e = Math.floor(Math.log(n) / Math.log(1000));
  return (n / Math.pow(1000, e)).toFixed(2) + "" + s[e];
}
