export class SocketStream<E> {
    private socket: WebSocket | null;
    private endpoint: string;
    private handler: (e: E) => any;
    private consecutivefailures: number = 0;

    constructor(endpoint: string = "wsapi") {
        this.endpoint = endpoint;
        this.socket = null;
        this.handler = e => 0;
        this.connect();
    }

    public setHandler(h: typeof this.handler) {
        this.handler = h;
    }

    private connect() {
        if (this.socket == null) {
            this.socket = new WebSocket(this.endpoint);
            // this.socket.onopen = () => { this.consecutivefailures = 0; };
            //this.socket.onerror = ;
            this.socket.onclose = (e) => {
                const timeout = 100 * Math.pow(2, this.consecutivefailures++);
                console.log("Socket closed, attempting reconnection in " + timeout + "ms");
                this.socket = null;//Clear the socket
                setTimeout(() => {
                    this.connect();//Attempt reconnection
                }, timeout);
            };
            this.socket.onmessage = (e) => { this.consecutivefailures = 0; this.handler(JSON.parse(e.data)) };
        } else {
            throw new Error("Websocket already active!");
        }
    }
}
