export type Link = { dpid1: string, port1: number, dpid2: string, port2: number };
export type PortStatsArray = [rx_packets: number, tx_packets: number, rx_bytes: number, tx_bytes: number, rx_dropped: number, tx_dropped: number, rx_errors: number, tx_errors: number, rx_frame_err: number, rx_over_err: number, rx_crc_err: number, collisions: number];
export type PortStatsData = {
  rx_packets: number,
  tx_packets: number,

  rx_bytes: number,
  tx_bytes: number,

  rx_dropped: number,
  tx_dropped: number,

  rx_errors: number,
  tx_errors: number,

  rx_frame_err: number,
  rx_over_err: number,
  rx_crc_err: number,

  collisions: number
};

export type Host = { mac: string, dpid: string, port: number };

export type AnyPoxEvent = { timestamp: number } & (
  | { type: 'InitalTopology', topology: Link[], switches: string[], hosts: Host[] }
  | { type: 'LinkEvent', add: boolean, } & Link
  | { type: 'SwitchPortStatsUpdate', dpid: string, portStats: { [port: number]: PortStatsArray } }
  | { type: 'SwitchEvent', add: boolean, dpid: string }
  | { type: 'HostEvent', join: boolean, } & Host
  | { type: 'HostMoveEvent', from_dpid: string, from_port: number, mac: string, to_dpid: string, to_port: number }
);

export type PoxEvent<T extends AnyPoxEvent['type'] = AnyPoxEvent['type']> = AnyPoxEvent & { type: T };

export const PORT_STATS_NAMES = ['rx_packets', 'tx_packets', 'rx_bytes', 'tx_bytes', 'rx_dropped', 'tx_dropped', 'rx_errors', 'tx_errors', 'rx_frame_err', 'rx_over_err', 'rx_crc_err', 'collisions'] as const;
export const PORT_STATS_INDICES = { rx_packets: 0, tx_packets: 1, rx_bytes: 2, tx_bytes: 3, rx_dropped: 4, tx_dropped: 5, rx_errors: 6, tx_errors: 7, rx_frame_err: 8, rx_over_err: 9, rx_crc_err: 10, collisions: 11 } as const;

export function parsePortStatsArray(x: PortStatsArray): PortStatsData {
  const portstats = { rx_packets: 0, tx_packets: 0, rx_bytes: 0, tx_bytes: 0, rx_dropped: 0, tx_dropped: 0, rx_errors: 0, tx_errors: 0, rx_frame_err: 0, rx_over_err: 0, rx_crc_err: 0, collisions: 0, };
  for (const [k, idx] of Object.entries(PORT_STATS_INDICES)) {
    const k2 = k as keyof typeof PORT_STATS_INDICES;
    portstats[k2] = x[idx];
    console.assert(portstats[k2] != undefined, "port stats array mismatch?", portstats);
  }
  return portstats;
}

export function zeroPortStats(): PortStatsData {
  return Object.fromEntries(PORT_STATS_NAMES.map(n => [n, 0])) as any;
}
