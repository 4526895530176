
import { nonnull } from "../utils";
import * as Utils from './chartutils';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import chartjspluginstreaming from 'chartjs-plugin-streaming';
// @ts-ignore
import 'chartjs-adapter-luxon';
import { DateTime, Duration } from "luxon";

import { Node } from './networkgraph';

import h from 'hyperscript';
import htm from 'htm';

const html = htm.bind(h);

import { PORT_STATS_INDICES, PORT_STATS_NAMES } from "../events";

Chart.register(chartjspluginstreaming);

const graphcontainer = nonnull(document.getElementById('graphs'));

function createGraphInternal(title: string) {

  const makeDataset = (i: number) => {
    const label = PORT_STATS_NAMES[i];
    return {
      label,
      backgroundColor: Utils.transparentize(Utils.COLORS_PAIRED[i], 0),
      borderColor: Utils.COLORS_PAIRED[i],
      borderWidth: 1,
      data: [],
      cubicInterpolationMode: 'monotone',
      yAxisID: label?.endsWith('_bytes') ? 'y1' : 'y',
    }
  };


  const data = {
    datasets: [...Array(PORT_STATS_NAMES.length)].map((_, i) => makeDataset(i)),
  };

  const table = h('table', {});
  table.innerHTML = `
    <thead>
      <tr>
        <th scope="col">Metric</th>
        <th scope="col">Last 2s</th>
        <th scope="col">Last 15s</th>
        <th scope="col">Last 60s</th>
      </tr>
    </thead>
    <tbody>
    <!-- <tr> -->
    <!--   <th scope="row">Sarah</th> -->
    <!--   <td>JavaScript frameworks</td> -->
    <!--   <td>29</td> -->
    <!--   <td>22</td> -->
    <!-- </tr> -->
    </tbody>
  `;
  const tbody = nonnull(table.querySelector('tbody'));

  const INTERVAL = 5000;
  const PERIODS = [2, 15, 60];

  for (const [metric,] of Object.entries(PORT_STATS_INDICES)) {
    const tr = h('tr', { 'data-metric': metric },
      h('th', { scope: 'row' }, metric),
      ...PERIODS.map(p => h('td', { 'data-interval': p }))
    );
    tbody.appendChild(tr);
  }

  const onRefresh = (chart: Chart) => {
    const now = Date.now();
    const dtnow = DateTime.fromMillis(now);

    let updated = true;
    // chart.data.datasets.forEach(dataset => {
    //   const prev = dataset.data.at(-1);
    //   // @ts-ignore
    //   if (!prev || prev.x < dtnow.minus(Duration.fromMillis(INTERVAL))) {
    //     dataset.data.push({
    //       x: now,
    //       y: Utils.rand(0, 99)
    //     });
    //     updated = true;
    //     // console.log(dataset.data.length);
    //   }
    // });

    if (updated) {
      for (const [metric, idx] of Object.entries(PORT_STATS_INDICES)) {
        const dataset = nonnull(chart.data.datasets[idx]).data;
        const stats = Object.fromEntries(PERIODS.map(p => [p, [Infinity, -Infinity] as [number, number]]));

        for (let i = dataset.length - 1; i >= 0; i--) {

          const point = nonnull(dataset[i]);
          for (const interval of Object.keys(stats)) {
            const cutoff = dtnow.minus(Duration.fromMillis(1000 * +interval));
            // @ts-ignore
            if (cutoff < point.x) {
              // @ts-ignore
              stats[interval][0] = Math.min(stats[interval][0], point.y);
              // @ts-ignore
              stats[interval][1] = Math.max(stats[interval][1], point.y);
            }
          }

        }

        const round = (x: number) => isFinite(x) ? `${Math.ceil(x)}`.padStart(2, '\u2007') : 'unknown';
        const row = (min: number, max: number) => `${round(min)} \u2013 ${round(max)}`;

        for (const [interval, [min, max]] of Object.entries(stats)) {
          const td: HTMLTableCellElement = nonnull(tbody.querySelector(`[data-metric="${metric}"] [data-interval="${interval}"]`)) as any;
          const newtext = row(min, max);
          if (newtext !== td.textContent && td.textContent) {
            td.style.backgroundColor = 'rgba(0,0,0,10%)';
            const fadeout = 1000;
            td.style.transition = `background-color ${fadeout}ms ease-out`;
            setTimeout(() => td.style.backgroundColor = 'rgba(0,0,0,0)', fadeout);
          }
          td.textContent = row(min, max);
        }
      }

    }
  };

  const config: ChartConfiguration = {
    type: 'line',
    data: data,
    options: {
      scales: {
        x: {
          type: 'realtime',
          realtime: {
            duration: 20000,
            refresh: INTERVAL,
            delay: INTERVAL * 2,
            onRefresh: onRefresh
          },
        },
        y: {
          title: {
            display: true,
            text: 'Count / second',
          },
          type: 'linear',
          min: 0,
          suggestedMax: 100,
        },
        y1: {
          title: {
            display: true,
            text: 'Bytes / second',
          },
          type: 'linear',
          display: true,
          position: 'right',
          min: 0,
          suggestedMax: 100,

          // grid line settings
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      },
      interaction: {
        intersect: false
      }
    }
  };

  const canvas = h('canvas');
  const d: HTMLDivElement = h('div.generatedgraph', { 'data-title': title, style: 'display: none; max-width: 600px;' },
    h('h3', {}, title),
    canvas,
    h('h4', {}, 'Summary statistics'),
    h('p', {}, 'Displays minimum and maximum of each metric over the given time period, for easier spotting of errors.'),
    table,
  );
  if (graphcontainer.children.length === 0)
    d.style.display = '';
  graphcontainer.appendChild(d);
  let plot = new Chart(nonnull(canvas.getContext('2d')), config);
  plot.update();

  return plot;
}

export function makeGraph(node: Node): Chart {
  console.log('making graph', node);

  return createGraphInternal(node.label);
}

export function highlightGraph(node: Node | null) {
  document.querySelectorAll<HTMLElement>('.generatedgraph')
    .forEach((el) => {
      const show = el.dataset['title'] === node?.label;
      el.style.display = show ? '' : 'none';
    });
}


export function removeGraph(node: Node) {
  document.querySelectorAll<HTMLElement>('.generatedgraph')
    .forEach((el) => {
      if (el.dataset['title'] === node.label) {
        el.parentNode!.removeChild(el);
      }
    });

}
