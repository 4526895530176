import { Network, Image } from 'vis-network/esnext/esm';
import { makeNetworkGraph, Node, Edge } from './ui/networkgraph';
import * as timeseries from './ui/timeseries';
import { nonnull } from './utils';

var container = nonnull(document.getElementById("mynetwork"));
const eventheading = nonnull(document.getElementById("eventSpanHeading"));
const eventcontent = nonnull(document.getElementById("eventSpanContent"));

const { network, nodes, edges } = makeNetworkGraph({ container, eventheading, eventcontent });

const edgeMap: { [dpid: string]: { [port: number]: { dpid: string, port: number } } } = {};

network.on("click", function(this: Network, params) {
  const id = this.getNodeAt(params.pointer.DOM);
  console.log(
    "click event, getNodeAt returns: " + id
  );
  if (id) {
    const node = nodes.get(id);
    timeseries.highlightGraph(node);
  }
});


import { SocketStream } from './ws';
import { Host, Link, parsePortStatsArray, PORT_STATS_INDICES, PORT_STATS_NAMES, PortStatsData, PoxEvent, zeroPortStats } from './events';
import Chart from 'chart.js/auto';
import { FullItem } from 'vis-data/declarations/data-interface';

const socket = new SocketStream<PoxEvent>('http://localhost:8000/coms/wsapi/');

const graphs: { [x: string]: Chart } = {};

function isMac(dpid: string) {
  const colons = dpid.match(/:/g)?.length ?? 0;
  return colons >= 5;
}

function nodeImages(dpid: string): { selected: string, unselected: string } {
  // if there are 5 colons, it's /probably/ a mac address for a HOST. otherwise, SWITCH.
  const base = isMac(dpid) ? 'computer' : 'switch';
  const selected = base + 'error.png';
  const unselected = base + '.png';
  return { selected, unselected };
}

function getOrInsertNode(dpid: string) {
  const dataset = nodes.getDataSet();
  if (!dataset.get(dpid)) {
    const node: any = { id: dpid, title: 'hover text for ' + dpid, label: '' + dpid };
    node.shape = 'image';
    // <a href="https://www.flaticon.com/free-icons/hub" title="hub icons">Hub icons created by IconBaandar - Flaticon</a>
    // https://www.flaticon.com/packs/communication-filled-line-14763809
    node.image = nodeImages(dpid);
    node.size = 18;
    dataset.add(node);
    graphs[dpid] = timeseries.makeGraph(node);
  }
}

function clearNetwork() {
  edges.getDataSet().clear();
  nodes.getDataSet().clear();
  network.redraw();
}

function edgeId(dpid1: string, port1: number, dpid2: string, port2: number) {
  return `${dpid1}.${port1}->${dpid2}.${port2}`;
}

function getOrInsertEdge(dpid1: string, port1: number, dpid2: string, port2: number): Edge {
  getOrInsertNode(dpid1);
  getOrInsertNode(dpid2);
  const edge: any = { id: edgeId(dpid1, port1, dpid2, port2), from: dpid1, to: dpid2, fromport: port1, toport: port2, };
  edge.title = edge.id;
  const dataset = edges;
  const oldedge = ifActiveEdge(dataset.get(edge.id as string) as any);
  if (oldedge) {
    return oldedge;
  }
  if (!dataset.get(edge.id as string)) {
    dataset.add(edge);
  }
  edge.color = { opacity: 1 };
  (edgeMap[dpid1] ??= {})[port1] = { dpid: dpid2, port: port2 };
  edges.update(edge);
  return edge;
}

function ifActiveEdge(e: Edge | null): Edge | null {
  return (!e || (e.color?.opacity ?? 1) === 0) ? null : e;
}

function removeEdge(e: Edge) {
  console.warn('removing edge', e);
  // edges.getDataSet().remove(e);
  delete (edgeMap[e.from] ??= {})[e.fromport];
  // @ts-ignore
  (e.color ??= {}).opacity = 0;
  edges.update(e);
}

/// removes a switch
function removeNode(node: Node) {
  for (const adj of network.getConnectedEdges(node.id)) {
    const edge = (edges.get(adj));
    if (!edge) continue; // already handled
    edges.remove(edge);
    // if this switch had attached hosts, propagate deletion to its hosts.
    if (isMac(edge.from as any)) {
      removeNode(nodes.get(edge.from!)!)
    } else if (isMac(edge.to as any)) {
      removeNode(nodes.get(edge.to!)!);
    }
  }
  timeseries.removeGraph(node);
  nodes.remove(node);
  delete prevstats[node.id];
  delete prevtx[node.id];
  delete edgeMap[node.id];
  graphs[node.id]!.destroy();
}

function handleLink(mode: 'add' | 'remove', link: Link) {
  const { dpid1, dpid2, port1, port2 } = link;
  const edge = getOrInsertEdge(dpid1, port1, dpid2, port2);
  if (mode === 'remove') {
    removeEdge(edge);
    for (const dpid of [dpid1, dpid2]) {
      let unused = true;
      for (const edgeid of network.getConnectedEdges(dpid)) {
        unused &&= !!edgeid.toString().match(/:/) || !ifActiveEdge(edges.get(edgeid) as any);
      }
      if (unused) {
        removeNode(nonnull(nodes.get(dpid)));
      }
    }
  }
}

function handleSwitch(mode: 'add' | 'remove', dpid: string) {
  getOrInsertNode(dpid);
  if (mode === 'remove') {
    nodes.getDataSet().remove(dpid);
  }
}


function handleInitial(links: Link[], switches: string[], hosts: Host[]) {
  clearNetwork();
  for (const link of links) {
    handleLink('add', link);
  }
  for (const dpid of switches) {
    getOrInsertNode(dpid);
  }
  for (const host of hosts) {
    handleHostEvent('add', host);
  }
}


function handleHostEvent(mode: 'add' | 'remove', host: Host) {
  handleLink(mode, { dpid1: host.dpid, port1: host.port, dpid2: host.mac, port2: -1 });
  handleLink(mode, { dpid2: host.dpid, port2: host.port, dpid1: host.mac, port1: -1 });
  if (mode === 'remove') {
    handleLink(mode, { dpid1: host.dpid, port1: host.port, dpid2: host.mac, port2: -1 });
    handleLink(mode, { dpid2: host.dpid, port2: host.port, dpid1: host.mac, port1: -1 });
  }
}


const prevstats: { [dpid: string]: [number, PortStatsData] } = {};
const prevtx: { [dpid: string]: { [port: string]: number } } = {};
function handlePortStats(dpid: string, data: { [port: number]: PortStatsData }) {
  const graph = graphs[dpid];
  console.assert(!!graph);
  const now = Date.now();

  if (graph) {
    prevstats[dpid] ??= [0, zeroPortStats()];
    const [prevt, prev] = prevstats[dpid];
    const dt = now - prevt;
    prevstats[dpid][0] = now;
    const sum = zeroPortStats();

    let foundDestinations = 0;
    // sum statistics across all ports
    for (const [port, stats] of Object.entries(data)) {
      for (const [m, v] of Object.entries(stats)) {
        const metric = m as keyof typeof PORT_STATS_INDICES;
        sum[metric] += v;
      }

      // thicken edge for transmitted data.
      const dest = (edgeMap[dpid]?.[parseInt(port)]);
      if (dest) {
        foundDestinations++;
        const edge = nonnull(edges.get(edgeId(dpid, parseInt(port), dest.dpid, dest.port)));
        (prevtx[dpid] ??= {})[port] ??= 0;
        const tx = (stats.tx_bytes - prevtx[dpid]![port]!) / dt;
        prevtx[dpid]![port]! = stats.tx_bytes;
        edge.width = Math.max(1, Math.log(Math.max(1, tx)) / Math.log(50));
        // edges.update();
        edges.update(edge);
        // console.log('set width ' + JSON.stringify(edge) + 'to ' + stats.tx_bytes);
      } else {
        // console.log(edgeMap);
        // debugger;
      }

    }

    if (foundDestinations == 0) {
      console.warn('no destinations at all for ' + dpid, data);
    }

    for (const [m, v] of Object.entries(sum)) {
      const metric = m as keyof typeof PORT_STATS_INDICES;
      const y2 = v - (prev[metric] ?? 0);
      graph.data.datasets[PORT_STATS_INDICES[metric]]!.data.push({
        x: now,
        // @ts-ignore
        y: y2 / dt,
      });
      prev[metric] = v;
    }

  }
}

function handler(x: PoxEvent) {
  console.debug(x);
  if (x.type === 'InitalTopology') {
    handleInitial(x.topology, x.switches, x.hosts);
  } else if (x.type === 'LinkEvent') {
    handleLink(x.add ? 'add' : 'remove', x);
  } else if (x.type === 'SwitchEvent') {
    handleSwitch(x.add ? 'add' : 'remove', x.dpid);
  } else if (x.type === 'SwitchPortStatsUpdate') {
    const portstats = Object.fromEntries(
      Object.entries(x.portStats).map(([k, v]) => [k, parsePortStatsArray(v)]));
    handlePortStats(x.dpid, portstats);
  } else if (x.type === 'HostEvent') {
    handleHostEvent(x.join ? 'add' : 'remove', x);
  } else if (x.type === 'HostMoveEvent') {
    handleHostEvent('remove', { dpid: x.from_dpid, port: x.from_port, mac: x.mac });
    handleHostEvent('add', { dpid: x.to_dpid, port: x.to_port, mac: x.mac });
  } else {
    console.assert(false, "unsupported event: ", x);
  }
}

socket.setHandler(handler);


