import { DataSet } from 'vis-data/esnext';
import * as vis from 'vis-network/esnext';
import 'vis-network/styles/vis-network.css';

export type Node = { id: string, label: string, title: string, };
export type Edge = { id: string, from: string, to: string, fromport: number, toport: number };
export type Params = { container: HTMLElement, eventheading: HTMLElement, eventcontent: HTMLElement };

export function makeNetworkGraph({ container, eventheading, eventcontent }: Params) {

  // create an array with nodes
  var nodes = new DataSet([
    // { id: 1, label: "Node 1", title: "I have a popup!" },
    // { id: 2, label: "Node 2", title: "I have a popup!" },
    // { id: 3, label: "Node 3", title: "I have a popup!" },
    // { id: 4, label: "Node 4", title: "I have a popup!" },
    // { id: 5, label: "Node 5", title: "I have a popup!" },
  ] as Node[]);

  // create an array with edges
  var edges = new DataSet<vis.Edge, 'id'>([
    // { from: 1, to: 3 },
    // { from: 1, to: 2 },
    // { from: 2, to: 4 },
    // { from: 2, to: 5 },
  ] as Edge[]);

  var data: vis.Data = {
    nodes: nodes,
    edges: edges,
  };

  var options = {
    interaction: { hover: true },
    manipulation: {
      enabled: false,
    },
    edges: {
      arrows: {
        middle: {
          enabled: true,
        }
      },
      // scaling: {
      //   min: 1,
      //   max: 10,
      //   customScalingFunction: (min: number, max: number, total: number, value: number) => {
      //     return Math.max(0, Math.min(1, value / total));
      //   }
      // },
      // value: 0,
    }
  };

  var network = new vis.Network(container, data, options);

  network.on("click", function(this: vis.Network, params) {
    params.event = "[original event]";
    eventheading.innerText = "Click event:";
    eventcontent.innerText = JSON.stringify(
      params,
      null,
      4
    );
  });

  network.on("doubleClick", function(params) {
    params.event = "[original event]";
    eventheading.innerText = "doubleClick event:";
    eventcontent.innerText = JSON.stringify(
      params,
      null,
      4
    );
  });
  network.on("oncontext", function(params) {
    params.event = "[original event]";
    eventheading.innerText =
      "oncontext (right click) event:";
    eventcontent.innerText = JSON.stringify(
      params,
      null,
      4
    );
  });
  network.on("dragStart", function(this: vis.Network, params) {
    // There's no point in displaying this event on screen, it gets immediately overwritten
    params.event = "[original event]";
    console.log("dragStart Event:", params);
    console.log(
      "dragStart event, getNodeAt returns: " + this.getNodeAt(params.pointer.DOM)
    );
  });
  network.on("dragging", function(params) {
    params.event = "[original event]";
    eventheading.innerText = "dragging event:";
    eventcontent.innerText = JSON.stringify(
      params,
      null,
      4
    );
  });
  network.on("dragEnd", function(this: vis.Network, params) {
    params.event = "[original event]";
    eventheading.innerText = "dragEnd event:";
    eventcontent.innerText = JSON.stringify(
      params,
      null,
      4
    );
    console.log("dragEnd Event:", params);
    console.log(
      "dragEnd event, getNodeAt returns: " + this.getNodeAt(params.pointer.DOM)
    );
  });
  network.on("controlNodeDragging", function(params) {
    params.event = "[original event]";
    eventheading.innerText =
      "control node dragging event:";
    eventcontent.innerText = JSON.stringify(
      params,
      null,
      4
    );
  });
  network.on("controlNodeDragEnd", function(params) {
    params.event = "[original event]";
    eventheading.innerText =
      "control node drag end event:";
    eventcontent.innerText = JSON.stringify(
      params,
      null,
      4
    );
    console.log("controlNodeDragEnd Event:", params);
  });
  network.on("zoom", function(params) {
    eventheading.innerText = "zoom event:";
    eventcontent.innerText = JSON.stringify(
      params,
      null,
      4
    );
  });
  network.on("showPopup", function(params) {
    eventheading.innerText = "showPopup event: ";
    eventcontent.innerText = JSON.stringify(
      params,
      null,
      4
    );
  });
  network.on("hidePopup", function() {
    console.log("hidePopup Event");
  });
  network.on("select", function(params) {
    console.log("select Event:", params);
  });
  network.on("selectNode", function(params) {
    console.log("selectNode Event:", params);
  });
  network.on("selectEdge", function(params) {
    console.log("selectEdge Event:", params);
  });
  network.on("deselectNode", function(params) {
    console.log("deselectNode Event:", params);
  });
  network.on("deselectEdge", function(params) {
    console.log("deselectEdge Event:", params);
  });
  network.on("hoverNode", function(params) {
    console.log("hoverNode Event:", params);
  });
  network.on("hoverEdge", function(params) {
    console.log("hoverEdge Event:", params);
  });
  network.on("blurNode", function(params) {
    console.log("blurNode Event:", params);
  });
  network.on("blurEdge", function(params) {
    console.log("blurEdge Event:", params);
  });


  let lastPosition: any = null;
  const max_zoom = 3;
  const min_zoom = 0.3;
  network.on("zoom", function(params) {
    if (params.scale < min_zoom || params.scale > max_zoom) { // adjust this value according to your requirement
      network.moveTo({
        position: lastPosition, // use the last position before zoom limit
        scale: params.scale > max_zoom ? max_zoom : min_zoom // this scale prevents zooming out beyond the desired limit
      });
    } else {
      // store the current position as the last position before zoom limit
      lastPosition = network.getViewPosition();
    }
  });
  // on pan, store the current position
  network.on("dragEnd", function() {
    lastPosition = network.getViewPosition();
  });
  return { network, nodes, edges };
}
